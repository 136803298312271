import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, Video } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Happy customers`}</h4>
    <p>{`Happy customers stay longer, recommend your destination, and they come back!`}</p>
    <br />
    <p>{`As a destination offering outdoor activities, you want your customers to be
able to choose activities that make them happy, have them leave with a smile and
make them want to come back. Activities that match the interests of your guests,
their physical and technical abilities, their equipment and their amount of time.`}</p>
    <br />
Every customer is different and has individual demands, which makes it important
to provide easy accessible and good information about the possibilities your destination
has to offer.
    <br />
    <Video className="w-full mx-auto shadow-lg" src="OlEwWGmbTIY" mdxType="Video" />
    <br />
    <h4>{`How to annoy your guests`}</h4>
    <p>{`Digitization and digitalization is spreading rapidly (more about digitalization
`}<a parentName="p" {...{
        "href": "https://www.trailguide.no/news/digitalization"
      }}>{`here`}</a>{`). You might have seen QR-codes
on print products linking to an app. Which could be a great way of
providing up-to-date information and the functionality of apps to the guests.`}</p>
    <br />
    <p>{`BUT! You scan the QR-Code which takes you to an app store. There you have
to download the app. That can take a long time because of its size,
a slow connection, or because your phone's storage is full and you have to delete
other apps first. Abroad there are often high fees for data usage.
Then you have to install it, register a user account, wait for the confirmation
e-mail, log in, and ONLY THEN you have access to the information and functions.`}</p>
    <br />
    <p>{`This is how it works in many of the existing solutions, a barrier
that is so high that most guests just give up in the process.`}</p>
    <br />
    <p>{`Now that sounds like marketing - and it actually is: With Trailguide you scan the QR-code
and the web-app immediately shows up with the right information. Try it with the
QR-Code below!`}</p>
    <br />
    <Image src="/solutions/accessinfodark2.png" className="w-full sm:w-2/3 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`1) Support good decisions`}</h4>
    <p>{`Make it easy for your guests to find the activities that make them happy! An web-app
like Trailguide combines the functionality of a native app with the accessibility of a
website. Your guests have everything they need on their phones in seconds.`}</p>
    <br />
    <p>{`Easily provide information and promote your activities through multiple touchpoints.
Embed your content interactively on your websites, share it in social media,
and connect printed maps, brochures and signs to your digital content. Users can also find
your destination and activities through the Trailguide app.`}</p>
    <br />
    <p>{`Trailguide allows your guests to easily get in touch with you, find information
they need, and choose activities making them happy and satisfied.`}</p>
    <Image src="/news/uploadtrails_6.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <br />
    <h4>{`2) Ensure a positive experience`}</h4>
    <p>{`From the pictures in the brochure the tour looks amazing! The pictures were taken
three years ago in summer time. Now the trail is worn out, the fall rain has left
big muddy areas, and the top part is already covered
in snow.`}</p>
    <br />
    <p>{`Printed information is hard to keep up to date. It is costly to adapt
it to seasonal changes. It is impossible to keep it up to date from day to day.`}</p>
    <br />
    <p>{`A digital tool allows you to communicate with your guests and provide current
information such as the conditions of trails and tours, which ones are in good
shape, which are free of snow, which are closed due to maintenance, which
should be avoided due to hunting season, forestry work, etc…`}</p>
    <br />
    <p>{`Communication ensures a positive customer experience and your guests will
feel valued and well taken care of.`}</p>
    <br />
    <p>{`Communication is also important in the other direction - from the guest to you.
Get to know what your guests think about your activities and about the actual conditions. Using
Trailguide your guests can provide valuable feedback with just a few clicks.`}</p>
    <Image src="/solutions/maintenance.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <br />
    <h4>{`3) Surpass expectations`}</h4>
    <p>{`It is always nice to get more than you expect! Outdoor activities often require
some sort of navigation to find the activity,
points of interest, and to help you stay on track. Text descriptions are not
enough. Printed maps are good for getting an overview, but for many hard
to use for navigation (and they have no idea where you are at any moment).`}</p>
    <br />
    <p>{`The Trailguide web-app not only provides information about the activities, but also
the functionality of showing your own location on the map.
Orientation becomes easy and almost everyone has a smartphone these days. For more
advanced users, GPX tracks off every activity can be downloaded for offline navigation.`}</p>
    <br />
    <p>{`To round off the day, the activities can be shared with friends and rated.`}</p>
    <Image src="/destinations/sospel_14.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Solutions for innovative tourism destinations`}</h4>
    <p>{`To make sure you get happy customers that will recommend your destination and
return to it, we built the Trailguide Professional platform. Digitize and
present all types of outdoor activities, reach your customers,
save costs, manage your infrastructure and traffic.`}</p>
    <br />
    <Image src="/solutions/presentation.png" className="w-3/4 mx-auto my-12" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      